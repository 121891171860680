<template>
  <LinkBase
    :to="to"
    :type="type"
    class="menu-item"
    :class="menuItemClasses"
    @click="emit('click')"
  >
    <slot />
  </LinkBase>
</template>

<script lang="ts" setup>
import { LinkBase, LinkType } from '@lasso/luikit'
import { computed } from 'vue'

const props = withDefaults(defineProps<{
  to: string
  selected: boolean
  type?: LinkType
}>(), {
  type: 'link',
})

const emit = defineEmits<{
  click: []
}>()

const menuItemClasses = computed(() => {
  return {
    'menu-item-active': props.selected,
    'menu-item-inactive': !props.selected,
  }
})
</script>

<style scoped>
.menu-item {
  @apply px-3 my-0.5 py-2 select-none w-full rounded-full;
}

.menu-item-active {
  @apply text-primary bg-primary-light;
}

.menu-item-inactive {
  @apply text-textSecondary hover:bg-base-300 hover:text-textSecondary active:bg-base-500;
}
</style>
