import { defineRefStore } from '@lasso/shared/utils'
import { useAccessControlBase, useUser, useUserFeatureToggles } from '@lasso/shared/hooks'
import { computed, reactive } from 'vue'

export const useAccessControl = defineRefStore('accessControl', () => {
  const { isSuperAdmin } = useUser()
  const { feature, hasFeature, permission, hasPermission } = useAccessControlBase()
  const { isFeatureToggledOn } = useUserFeatureToggles()

  const permissions = reactive({
    audiences: permission('audiences'),
    campaigns: permission('campaigns'),
    creatives: permission('creatives'),
    digitalEvents: permission('webevents'),
    locations: permission('locations'),
    brandSafety: permission('inventorylists'),
    customWorkflows: isSuperAdmin,
    endemic: permission('monetization'),
    directSold: permission('directsoldcampaigns'),
    reporting: permission('reports'),
    measurement: computed(() => hasPermission('measurement') || hasPermission('analytics')),
    accounts: permission('accounts'),
    users: permission('users'),
    roles: isSuperAdmin,
    featurePreview: permission('features'),
    adminOnly: isSuperAdmin,
  })

  const features = reactive({
    campaignMonitoringV2: computed(() => hasFeature('CAMPAIGN_MONITORING_V2') && isFeatureToggledOn('campaignMonitoringV2')),
    customWorkflows: feature('CUSTOMWORKFLOWS'),
    reportingV2: feature('REPORTING-2'),
    universalPixel: feature('UNIVERSAL_PIXELS'),
  })

  return { permissions, features }
})
