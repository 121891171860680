<template>
  <Sidenav v-if="ready" />
</template>

<script setup lang="ts">
import { hideLoader, showLoader } from '@lasso/shared/utils'
import { onUnmounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { Sidenav } from '@sidenav/components/Sidenav'
import { useInitLuikit } from '@sidenav/app/useInitLuikit'
import { useInitApi } from '@sidenav/app/useInitApi'

useInitLuikit()
useInitApi()

const router = useRouter()
const ready = ref(false)

router.isReady().then(() => {
  ready.value = true
})

watch(() => ready.value, (value) => {
  if (value) {
    hideLoader('sidenav')
  }
  else {
    showLoader('sidenav')
  }
}, { immediate: true })

onUnmounted(() => {
  hideLoader('sidenav')
})
</script>
