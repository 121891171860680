import { MenuItemConfigOrDivider } from './types'

export const menuItemsConfig: MenuItemConfigOrDivider[] = [
  {
    to: 'app/audience',
    label: 'Audience',
    icon: 'account_circle',
    visible: ({ permissions }) => permissions.audiences,
    gtm: { name: 'audience' },
  },
  {
    to: 'activation',
    label: 'Activation',
    icon: 'campaign',
    ignoredTos: ['activation/admin-only'],
    includedTos: ['app/activation', 'activation'],
    gtm: { name: 'activation', el: 'menu-accordion' },
    children: [
      {
        to: 'activation/campaign',
        label: 'Campaigns',
        visible: ({ permissions, features }) => permissions.campaigns && !features.campaignMonitoringV2,
        includedTos: ['app/activation/campaign'],
        gtm: { name: 'activation-campaigns' },
      },
      {
        to: 'app/activation/campaign',
        label: 'Campaigns',
        visible: ({ permissions, features }) => permissions.campaigns && features.campaignMonitoringV2,
        includedTos: ['activation/campaign'],
        gtm: { name: 'activation-campaigns' },
      },
      {
        to: 'activation/creative',
        label: 'Creatives',
        visible: ({ permissions }) => permissions.creatives,
        gtm: { name: 'activation-creatives' },
      },
      {
        to: 'activation/web-events',
        label: 'Digital Events',
        visible: ({ permissions, features }) => permissions.digitalEvents && !features.universalPixel,
        includedTos: ['app/activation/digital-events'],
        gtm: { name: 'activation-digital-events' },
      },
      {
        to: 'app/activation/digital-events',
        label: 'Digital Events',
        visible: ({ permissions, features }) => permissions.digitalEvents && features.universalPixel,
        includedTos: ['activation/web-events'],
        gtm: { name: 'activation-digital-events' },
      },
      {
        to: 'activation/location',
        label: 'Locations',
        visible: ({ permissions }) => permissions.locations,
        gtm: { name: 'activation-locations' },
      },
      {
        to: 'activation/brand-safety',
        label: 'Brand Safety',
        visible: ({ permissions }) => permissions.brandSafety,
        gtm: { name: 'activation-brand-safety' },
      },
      {
        to: 'app/workflows',
        label: 'Custom Workflows',
        visible: ({ permissions, features }) => permissions.customWorkflows && features.customWorkflows,
        gtm: { name: 'activation-custom-workflows' },
      },
    ],
  },
  {
    to: 'app/monetization',
    label: 'Monetization',
    icon: 'leaderboard',
    gtm: { name: 'monetization', el: 'menu-accordion' },
    children: [
      {
        to: 'app/monetization/endemic',
        label: 'Endemic',
        visible: ({ permissions }) => permissions.endemic,
        gtm: { name: 'monetization' },
      },
      {
        label: 'Direct Sold',
        to: 'app/monetization/directsold',
        visible: ({ permissions }) => permissions.directSold,
        gtm: { name: 'directSold' },
      },
    ],
  },
  {
    to: 'app/measurement',
    label: 'Measurement',
    icon: 'donut_small',
    visible: ({ permissions }) => permissions.measurement,
    gtm: { name: 'measurement' },
  },
  {
    to: 'reporting',
    label: 'Reporting',
    icon: 'calculate',
    visible: ({ permissions, features }) => permissions.reporting && !features.reportingV2,
    gtm: { name: 'reporting' },
  },
  {
    to: 'app/reporting',
    includedTos: ['reporting'],
    label: 'Reporting',
    icon: 'calculate',
    visible: ({ permissions, features }) => permissions.reporting && features.reportingV2,
    gtm: { name: 'reporting' },
  },
  {
    to: 'admin',
    label: 'Settings',
    icon: 'settings',
    gtm: { name: 'settings', el: 'menu-accordion' },
    children: [
      {
        to: 'admin/accounts',
        label: 'Accounts',
        visible: ({ permissions }) => permissions.accounts,
        gtm: { name: 'settings-accounts' },
      },
      {
        to: 'admin/users',
        label: 'Users',
        visible: ({ permissions }) => permissions.users,
        gtm: { name: 'settings-users' },
      },
      {
        to: 'admin/roles',
        label: 'Roles',
        visible: ({ permissions }) => permissions.roles,
      },
      {
        to: 'admin/features',
        label: 'Feature Preview',
        visible: ({ permissions }) => permissions.featurePreview,
        gtm: { name: 'feature-preview' },
      },
      {
        to: 'activation/admin-only',
        label: 'Admin Only',
        visible: ({ permissions }) => permissions.adminOnly,
        gtm: { name: 'admin-only' },
      },
    ],
  },
]
