import { App as VueApp, createApp, h } from 'vue'
import singleSpaVue from 'single-spa-vue'
import { createRouter } from '@sidenav/router'
import { appUnmountCleanupPlugin, errorHandlerPlugin } from '@lasso/shared/app'
import { createGtm } from '@gtm-support/vue-gtm'
import { getGtmConfig } from '@lasso/shared/configs'

import { createPinia } from 'pinia'

import App from './App.vue'

export const { bootstrap, mount, unmount } = singleSpaVue({
  createApp,
  appOptions: {
    render: () => h(App),
  },
  handleInstance(app: VueApp) {
    app
      .use(createRouter())
      .use(createPinia())
      .use(errorHandlerPlugin)
      .use(createGtm(getGtmConfig()))
      .use(appUnmountCleanupPlugin)
  },
})
