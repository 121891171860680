import { AxiosInstance } from 'axios'

import { SidenavService } from './services/SidenavService'

export class SidenavApi {
  public readonly sidenav: SidenavService

  constructor(public readonly request: AxiosInstance) {
    this.sidenav = new SidenavService(this.request)
  }
}
