import { TrackEventOptions } from '@gtm-support/vue-gtm'

export type GtmFixtureConfig = {
  name: string
  el?: string
}

export const generateGtmFixture = ({ name, el = 'menu-item' }: GtmFixtureConfig): TrackEventOptions => {
  return {
    event: `sidenav-${el}-${name}`,
    category: 'sidenav',
    action: 'click',
    label: `user clicked on the ${el}-${name}`,
  }
}
