import axios from 'axios'
import { useAuthInterceptors, useProvideAuthApi } from '@lasso/shared-auth'
import { formatError } from '@lasso/api-shared'
import { useProvideSidenavApi } from '@sidenav/hooks/useSidenavApi'

export const useInitApi = () => {
  const axiosLegacyInstance = axios.create({
    baseURL: process.env.API || '',
  })

  useProvideAuthApi(axiosLegacyInstance)

  const { setAuthToken, handleUnauthorized } = useAuthInterceptors()

  axiosLegacyInstance.interceptors.request.use(setAuthToken)
  axiosLegacyInstance.interceptors.response.use(undefined, handleUnauthorized)
  axiosLegacyInstance.interceptors.response.use(undefined, formatError)

  useProvideSidenavApi(axiosLegacyInstance)
}
