<template>
  <Box>
    <Paper class="search-result-group" rounded="none" flex alignItems="center" justify="between">
      <Box flex alignItems="center" py="1">
        <Typography pr="2" variant="subtitle2" capitalize>
          {{ groupKey }}
        </Typography>
        <Chip size="2xs" :label="itemsCount" />
      </Box>
      <Link v-if="itemsCount > 5" color="secondary" @click="toggleExpanded()">
        <Typography variant="caption1" capitalize>
          {{ expandText }}
        </Typography>
      </Link>
    </Paper>
    <Box flex direction="col">
      <Box v-for="item of limitedItems" :key="item.id" flex alignItems="center" spaceX="2" mt="2">
        <Chip variant="outlined" size="2xs" :label="item.id" />
        <Typography variant="caption1" truncateRow="two" color="inherit" capitalize>
          <Link :to="getLink(item)" class="link link-hover" @click="onClick()">
            {{ item.name }}
          </Link>
        </Typography>
      </Box>
    </Box>
  </Box>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { Box, Chip, Link, Paper, Typography } from '@lasso/luikit'
import { useAccessControl } from '@sidenav/hooks/useAccessControl'
import { useToggle } from '@vueuse/core'
import { SearchResultDTO } from '@sidenav/api'

const props = withDefaults(defineProps<{
  groupKey: string
  items: Array<SearchResultDTO>
}>(), {
  groupKey: 'Misc',
})

const emits = defineEmits<{
  close: []
}>()

const [isExpanded, toggleExpanded] = useToggle(false)
const expandText = computed(() => (isExpanded.value ? 'Collapse' : 'Expand'))
const itemsCount = computed(() => (props.items ? props.items?.length : 0))
const limitedItems = computed(() => (isExpanded.value ? props.items : props.items.slice(0, 5)))

const onClick = () => {
  emits('close')
}

const { features } = useAccessControl()

const getLink = (item: SearchResultDTO) => {
  if ((props.groupKey === 'Campaigns' || props.groupKey === 'AdGroups') && features.campaignMonitoringV2) {
    return item.linkNew || item.link
  }

  return item.link
}
</script>

<style scoped>
.search-result-group {
  position: sticky;
  top: 50px;
}
</style>
