<template>
  <Box>
    <slot name="default" :toggle="toggle" :isOpen="modelValueInternal" />
    <Box v-if="modelValueInternal && $slots.dropdown" class="accordion-divider" ml="6">
      <slot name="dropdown" />
    </Box>
  </Box>
</template>

<script lang="ts" setup>
import { useToggle, useVModel } from '@vueuse/core'
import { Box } from '@lasso/luikit'

const props = defineProps<{
  modelValue: boolean
}>()

const emit = defineEmits<{
  'update:modelValue': [boolean]
}>()

const modelValueInternal = useVModel(props, 'modelValue', emit)
const toggle = useToggle(modelValueInternal)
</script>

<style scoped>
.accordion-divider {
  @apply border-l border-solid
}
</style>
