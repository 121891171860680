import { ApiService } from '@lasso/api-shared'
import { AxiosResponse } from 'axios'
import { SearchResultDTO } from '@sidenav/api'

export class SidenavService extends ApiService {
  getSearchResults(keyword: string): Promise<AxiosResponse<SearchResultDTO[]>> {
    return this.request({
      url: 'api/search',
      method: 'GET',
      params: { keyword },
    })
  }
}
